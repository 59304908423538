/** @format */

const translationEn = {
	home: 'Home',
	about: 'About',
	aboutDescription:
		'Fabrique Entertainment is composed of Renata Di Leone and Giovanni Capalbo. The company is not strictly structured into specific sectors. Projects follow the ideas of the individual members, who adapt to various roles based on the origin of the ideas. Nevertheless, the producers collaborate and work together in the development of the stories, and later define their areas of expertise during the production phase. Renata Di Leone has an instinct for selecting stories that are connected to her African background, as she is Ethiopian. Giovanni Capalbo focuses on economic and financial strategies.',
	films: 'Filmography',
	news: 'News',
	contacts: 'Contacts',
	filmsList: 'List of films',
	addFilm: 'Add film',
	addArticle: 'Add article',
	contactsList: 'List of contacts',
	addContact: 'Add contact',
	forgotPassword: 'Reset Password',
	vision: 'Vision',
	visionDescription:
		"Fabrique Entertainment was founded in February 2014. The company's objective is to evaluate projects in line with Italian and European art-house cinema. In this direction, projects have been developed and realized to date, which have characterized its authorial vocation.",
	ethics: 'Ethics',
	ethicsDescription:
		'The ethics of our company are deeply linked to the respect for human values in work relationships, always placing the respect for ideas, art, the environment, culture, and especially the exploration of multiculturalism in the contemporary film world at the center.',
	company: 'The company',
	companyDescription:
		"Fabrique Entertainment was established in February 2014. The company's objective is to assess projects in line with Italian and European art-house cinema. To this day, they have developed projects that have embodied their authorial calling. Currently, Fabrique Entertainment is in the post-production phase of the documentary 'Fela, My Living God,' directed by Daniele Vicari, featuring the narration of Claudio Santamaria and music by Teho Teardo. The documentary is co-produced in collaboration with Cinecittà - Luce, Lokafilm (Slovenia), Grasshopper Films (UK), and Rai Cinema. Additionally, the company is in the development phase of the film 'Flaminio Zoo' (based on the screenplay by Heidrun Schleef and Guerino Di Leone and inspired by a true story) and the documentary 'Bobò, the Angel Who Saved My Life.' This film will be co-produced with Cinecittà - Luce and Casa Azul Films (Switzerland) and directed by Pippo Delbono.",
	genericInfo: {
		name: 'Name',
		surname: 'Surname',
		email: 'Email',
		number: 'Telephone number',
		ideas: 'We value the ideas',
	},
	password: 'Password',
	confirmPassword: 'Confirm password',
	title: 'Title',
	author: 'Author',
	tag: 'Tag',
	dateArticle: 'Date release article',
	director: 'A film by',
	productions: 'Production',
	producers: 'Producers',
	coProductions: 'Co-production',
	coProducers: 'Co-producers',
	collaborations: 'Collaborations',
	contributes: 'Contributes',
	actors: 'Actors',
	subject: 'Subject',
	screenwriter: 'Screenwriting',
	genres: 'Genre',
	projectState: 'Project State',
	directorOfPhotography: 'Photography',
	editing: 'Editing',
	scenography: 'Scenography',
	costumes: 'Costumes',
	music: 'Music',
	sound: 'Sound',
	soundDesign: 'Sound design',
	casting: 'Casting',
	lineProducer: 'Line producer',
	executiveProducers: 'Executive Producers',
	distributor: 'Distributor',
	salesAgent: 'Sales agent',
	firstAssistantDirector: 'First assistant director',
	synopsis: 'Synopsis',
	productionNotes: 'Production Notes',
	directorNotes: 'Director Notes',
	duration: 'Duration',
	minutes: 'Minutes',
	year: 'Year',
	typology: 'Typology',
	festivals: 'Festivals',
	links: 'Links',
	pressBook: 'Pressbook',
	competition: 'In Competition',
	award: 'Award',
	productionsLabels: {
		production: 'Production',
		addProduction: 'Add production',
		deleteProduction: 'Delete production',
	},
	producersLabels: {
		producer: 'Producer',
		addProducer: 'Add producer',
		deleteProducer: 'Delete producer',
	},
	coProductionsLabels: {
		coProduction: 'Co-production',
		addCoProduction: 'Add co-production',
		deleteCoProduction: 'Delete co-production',
	},
	coProducersLabels: {
		coProducer: 'Co-producer',
		addCoProducer: 'Add co-producer',
		deleteCoProducer: 'Delete co-producer',
	},
	collaborationsLabels: {
		collaboration: 'Collaboration',
		addCollaboration: 'Add collaboration',
		deleteCollaboration: 'Delete collaboration',
	},
	contributesLabels: {
		contribute: 'Contribute',
		addContribute: 'Add contribute',
		deleteContribute: 'Delete contribute',
	},
	actorsLabels: {
		actor: 'Actor',
		role: 'Role',
		addActor: 'Add Actor',
		deleteActor: 'Delete actor',
	},
	subjectLabels: {
		subject: 'Subject',
		addSubject: 'Add subject writer',
		deleteSubject: 'Delete subject writer',
	},
	screenwritersLabels: {
		screenwriter: 'Screenwriter',
		addScreenwriter: 'Add screenwriter',
		deleteScreenwriter: 'Delete screenwriter',
	},
	musicsLabels: {
		music: 'Composer',
		addMusic: 'Add Composer',
		deleteMusic: 'Delete Composer',
	},
	executiveProducersLabels: {
		executiveProducer: 'Executive Producer',
		addExecutiveProducer: 'Add Executive Producer',
		deleteExecutiveProducer: 'Delete Executive Producer',
	},
	festivalsLabels: {
		festival: 'Festival',
		addFestival: 'Add festival',
		deleteFestival: 'Delete festival',
	},
	cover: 'Film cover',
	profileCover: 'Profile picture',
	articleCover: 'Article cover',
	role: 'Role',
	bio: 'Biography',
	insertAction: 'Insert',
	modifyAction: 'Modify',
	confirmAction: 'Confirm',
	signInAction: 'Sign in',
	createAccount: 'Create account',
	modify: 'Modify',
	remove: 'Remove',
	description: 'Article description',
	labels: {
		signUpLabel: 'Create your account',
		loginLabel: 'Login',
		passwordLabel: 'Insert the new password',
		forgotLabel: 'If you had forget the password click here',
		emailLabel: 'Insert your email',
		addDbFilm: 'Add a film inside the database',
		modifyDbFilm: 'Modify a film inside the database',
		addDbContact: 'Add a contact inside the database',
		modifyDbContact: 'Modify a contact inside the database',
		addDbArticle: 'Add an article inside the database',
		modifyDbArticle: 'Modify an article inside the database',
		obligatory: 'Fields marked with (*) are mandatory',
	},
	linksLabels: {
		trailer: 'Trailer',
		imdb: 'Link imdb',
		instagram: 'Link instagram',
		facebook: 'Link facebook',
		articleLink: 'Link article',
	},
	errors: {
		dbCrud:
			'Problem during the compilation of the database, try to fill the form again',
		signUp:
			'Problem during the account creation, try to compile the form again.',
		login: 'Problem during the sign in, try to compile the form again.',
		forgotPassword:
			'Problem during the request of password reset, try to make a new request',
		resetPassword:
			'Problema during the reset of the password, try to make a new request',
		filmError: 'The film selected was not found, go back to the previous page',
		contactError:
			'The contact selected was not found, go back to the previous page',
		contactErrorDelete: 'Wan not possible to delete the contact, try again',
		filmErrorDelete: 'Was not possible to delete the film, try again',
		dataExistenceError:
			'There are no values for this research, try to make it an other one or refresh the page',
		contactExistenceError:
			'There are not contacts for this research, try to make it an other one or refresh the page',
	},
};

export { translationEn };
