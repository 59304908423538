/** @format */

const translationIt = {
	home: 'Home',
	about: 'Il team',
	aboutDescription:
		"Fabrique Entertainment e' formata da Renata Di Leone e Giovanni Capalbo. La società non e' strettamente strutturata nei settori. I progetti seguono le idee dei singoli esponenti, i quali si adattano a interpretare funzioni diverse in base alla paternità delle idee. Ciò nonostante, i produttori, sono uniti e operativi nello sviluppo delle storie per poi definire i loro ambiti nella fase di produzione. Renata Di Leone , ha l'istinto nel scegliere le storie che sono connesse con il suo mondo africano, essendo lei etiope. Giovanni Capalbo lavora sulle strategie economiche e finanziarie.",
	films: 'Filmografia',
	news: 'News',
	contacts: 'Contatti',
	filmsList: 'Lista dei film',
	addFilm: 'Aggiungi un film',
	addArticle: 'Aggiungi un articolo',
	contactsList: 'Lista dei contatti',
	addContact: 'Aggiungi un contatto',
	forgotPassword: 'Reimposta la password',
	vision: 'Visione',
	visionDescription:
		'Fabrique Entertainment nasce a Febbraio del 2014. L’obiettivo della società è quello di valutare progetti in linea con il cinema d’autore italiano ed europeo. In tale direzione sono stati sviluppati e realizzati ad oggi progetti che hanno caratterizzato la sua vocazione autoriale.',
	ethics: 'Etica',
	ethicsDescription:
		'L’etica della nostra società è legata profondamente al rispetto dei valori umani nelle relazioni di lavoro, mettendo sempre al centro il rispetto delle idee, dell’arte, dell’ambiente, della cultura e in particolare dell’esplorazione della multiculturalità nel mondo cinematografico contemporaneo.',
	company: 'la società',
	companyDescription:
		'Fabrique Entertainment nasce a Febbraio del 2014. L’obiettivo della società è quello di valutare progetti in linea con il cinema d’autore italiano ed europeo. In tale direzione sono stati sviluppati ad oggi progetti che hanno caratterizzato la sua vocazione autoriale. Attualmente Fabrique Entertainment è in fase di post-produzione con il documentario “Fela, il mio Dio Vivente” per la regia di Daniele Vicari, con voce narrante di Claudio Santamaria e musiche di Teho Teardo, co-prodotto con Cinecittà - Luce, Lokafilm (Slovenia), Grasshopper Films (UK) e in collaborazione con Rai Cinema ed è in fase di sviluppo per il film “Flaminio Zoo” (per la sceneggiatura di Heidrun Schleef e Guerino Di Leone e ispirato a una storia vera) e per il documentario “Bobò, l’angelo che mi ha salvato la vita”, film che sarà co-prodotto con Cinecittà - Luce e Casa Azul Films (Svizzera), per la regia di Pippo Delbono.',
	genericInfo: {
		name: 'Nome',
		surname: 'Cognome',
		email: 'Email',
		number: 'Numero di telefono',
		ideas: 'Diamo valore alle idee',
	},
	password: 'Password',
	confirmPassword: 'Conferma password',
	title: 'Titolo',
	author: 'Autore',
	tag: 'Tag',
	dateArticle: 'Data uscita articolo',
	director: 'Regia',
	productions: 'Produzione',
	producers: 'Produttori',
	coProductions: 'Co-produzione',
	coProducers: 'Co-produttori',
	collaborations: 'Collaborazioni',
	contributes: 'Contributi',
	actors: 'Attori',
	subject: 'Soggetto',
	screenwriter: 'Sceneggiatura',
	genres: 'Genere',
	projectState: 'Stato progetto',
	directorOfPhotography: 'Fotografia',
	editing: 'Montaggio',
	scenography: 'Scenografia',
	costumes: 'Costumi',
	music: 'Musiche',
	sound: 'Suono',
	soundDesign: 'Design del suono',
	casting: 'Casting',
	lineProducer: 'Organizzatore generale',
	executiveProducers: 'Produttori esecutivi',
	distributor: 'Distributore',
	salesAgent: 'Sales agent',
	firstAssistantDirector: 'Aiuto regista',
	synopsis: 'Sinossi',
	productionNotes: 'Note di produzione',
	directorNotes: 'Note di regia',
	duration: 'Durata',
	minutes: 'Minuti',
	year: 'Anno',
	typology: 'Tipologia',
	festivals: 'Festivals',
	links: 'Links',
	pressBook: 'Pressbook',
	competition: 'In Concorso',
	award: 'Premio',
	productionsLabels: {
		production: 'Produzione',
		addProduction: 'Aggiungi produzione',
		deleteProduction: 'Cancella produzione',
	},
	producersLabels: {
		producer: 'Produttore',
		addProducer: 'Aggiungi produttore',
		deleteProducer: 'Cancella produttore',
	},
	coProductionsLabels: {
		coProduction: 'Co-produzione',
		addCoProduction: 'Aggiungi co-produzione',
		deleteCoProduction: 'Cancella co-produzione',
	},
	coProducersLabels: {
		coProducer: 'Co-produttore',
		addCoProducer: 'Aggiungi co-produttore',
		deleteCoProducer: 'Cancella co-produttore',
	},
	collaborationsLabels: {
		collaboration: 'Collaborazione',
		addCollaboration: 'Aggiungi collaborazione',
		deleteCollaboration: 'Cancella collaborazione',
	},
	contributesLabels: {
		contribute: 'Contributo',
		addContribute: 'Aggiungi contributo',
		deleteContribute: 'Cancella contributo',
	},
	actorsLabels: {
		actor: 'Attore',
		role: 'Ruolo',
		addActor: 'Aggiungi attore',
		deleteActor: 'Cancella attore',
	},
	subjectsLabels: {
		subject: 'Scrittore soggetto',
		addSubject: 'Aggiungi scrittore soggetto',
		deleteSubject: 'Cancella scrittore soggetto',
	},
	screenwritersLabels: {
		screenwriter: 'Sceneggiatore',
		addScreenwriter: 'Aggiungi sceneggiatore',
		deleteScreenwriter: 'Cancella sceneggiatore',
	},
	musicsLabels: {
		music: 'Musicista',
		addMusic: 'Aggiungi Musicista',
		deleteMusic: 'Cancella Musicista',
	},
	executiveProducersLabels: {
		executiveProducer: 'Produttore esecutivo',
		addExecutiveProducer: 'Aggiungi produttore esecutivo',
		deleteExecutiveProducer: 'Cancella produttore esecutivo',
	},
	festivalsLabels: {
		festival: 'Festival',
		addFestival: 'Aggiungi festival',
		deleteFestival: 'Cancella festival',
	},
	cover: 'Copertina del film',
	profileCover: 'Foto del profilo',
	articleCover: 'Foto articolo',
	role: 'Ruolo',
	bio: 'Biografia',
	insertAction: 'Inserisci',
	modifyAction: 'Modifica',
	confirmAction: 'Conferma',
	signInAction: 'Accedi',
	createAccount: 'Crea account',
	modify: 'Modifica',
	remove: 'Rimuovi',
	description: 'Descrizione articolo',
	labels: {
		signUpLabel: 'Crea il tuo account',
		loginLabel: 'Login',
		passwordLabel: 'Inserisci la nuova password',
		forgotLabel: 'Se hai dimenticato la password clicca qui',
		emailLabel: 'Inserisci la tua email',
		addDbFilm: 'Aggiungere un film al database',
		modifyDbFilm: 'Modificare un film del database',
		addDbContact: 'Aggiungere un contatto al database',
		modifyDbContact: 'Modificare un contatto del database',
		addDbArticle: 'Aggiungere un articolo al database',
		modifyDbArticle: 'Modificare un articolo del database',
		obligatory: 'Campi contrassegnati con (*) sono obbligatori',
	},
	linksLabels: {
		trailer: 'Trailer',
		imdb: 'Link imdb',
		instagram: 'Link instagram',
		facebook: 'Link facebook',
		articleLink: 'Link articolo',
	},
	errors: {
		dbCrud:
			'Problema nella compilazione del database, effettuare nuovamente la compilazione del form',
		signUp:
			"Problema durante la creazione dell'account, compilare nuovamente il form, i dati potrebbero essere errati.",
		login:
			"Problema durante l'accesso, compilare nuovamente il form, i dati potrebbero essere errati.",
		forgotPassword:
			'Problema durante la richiesta di ripristino della password, provare a rieffettuare i passaggi',
		resetPassword:
			'Problema durante il ripristino della password, provare a rieffettuare i passaggi',
		filmError:
			'Il film selezionato non è stato trovato, tornare alla pagina precedente',
		contactError:
			'Il contatto selezionato non è stato trovato, tornare alla pagina precedente',
		contactErrorDelete:
			'Non è stato possibile cancellare il contatto, riprovare',
		filmErrorDelete: 'Non è stato possibile cancellare il film, riprovare',
		dataExistenceError:
			'Non sono presenti valori per questa ricerca, provare a effettuarne un altra, oppure ricaricare la pagina',
		contactExistenceError:
			'Non sono presenti contatti per questa ricerca, provare a effettuarne un altra, oppure ricaricare la pagina',
	},
};

export { translationIt };
